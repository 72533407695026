/* home.css */


html, body {
    overflow-x: hidden; /* Prevent horizontal scrolling */
    margin: 0;
    padding: 0;
    width: 100%;
}

.home {
    width: 100vw;
    height: 322.5vw;
    background-repeat: no-repeat;
    background-size: 100vw;
    background-image: url(/Users/ethantam/Desktop/etweb/src/assets/ColorBackground.png);
}

.fade-in {
    opacity: 0;
    transition: opacity 3s ease-in;
}

.visible {
    opacity: 1;
}

.slide-in-left {
    animation: slide-in-left 3s ease-out forwards;
}

@keyframes slide-in-left {
    0% {
        transform: translateX(-50vw); /* Start from outside the left side of the screen */
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

.slide-in-right {
    animation: slide-in-right 3s ease-out forwards;
}

@keyframes slide-in-right {
    0% {
        transform: translateX(50vw); /* Start from outside the right side of the screen */
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

.slide-in-top {
    animation: slide-in-top 4s ease-out forwards;
}

@keyframes slide-in-top {
    0% {
        transform: translateY(100vh); /* Start from outside the bottom of the screen */
        opacity: 0;
    }
    100% {
        transform: translateY(0); /* Slide to the top of the screen */
        opacity: 1;
    }
}

.topDivision {
    position: relative;
    display: flex;
    padding-top: 3vw;
}

.welcomeDescription {
    position: relative;
    width: 100%;
    height: 40vw; /* Adjust based on your layout */
    display: flex;
    justify-content: center;
    align-items: center; /* Center children vertically */
    z-index: 2; /* Ensure it overlaps below FallBack but above Bridge */
}

.welcome {
    position: relative;
    top: 0;  /* Adjust based on your layout */
    left: 0vw;  /* Position welcome appropriately */
    height: 30vw;
    width: 40vw;
}

.LeafBack {
    position: absolute;
    top: 0;  /* Adjust based on your layout */
    right: 0; /* Position LeafBack on the right */
    height: 90vw;
    width: 50vw;
    opacity: 0; /* Ensure it starts hidden */
    z-index: 3; /* Ensure it overlaps below FallBack */
}

.FallBack {
    position: absolute;
    top: 15vw;  /* Adjust based on your layout */
    left: 0; /* Position FallBack on the left */
    height: 90vw;
    width: 50vw;
    opacity: 0; /* Ensure it starts hidden */
    z-index: 4; /* Ensure it overlaps on top of everything */
}

.GreekBack {
    position: absolute;
    top: 94.5vw;  /* Adjust based on your layout */
    left: 0; /* Position FallBack on the left */
    height: 228vw;
    width: 100vw;
    opacity: 0; /* Ensure it starts hidden */
    z-index: 4; /* Ensure it overlaps on top of everything */
}

.woodBack {
    position: absolute;
    top: 226.6vw;  /* Adjust based on your layout */
    left: 2.5vw; /* Position FallBack on the left */
    height: 55.5vw;
    width: 97vw;
    opacity: 0; /* Ensure it starts hidden */
    z-index: 3; /* Ensure it overlaps on top of everything */
}

.Bridge {
    position: absolute;
    top: 33vw;  /* Adjust based on your layout */
    left: 26vw;  /* Adjust based on your layout */
    height: 10vw;
    width: 30vw;
    z-index: 1; /* Ensure it overlaps below Welcome */
}

.csDescription {
    background-repeat: no-repeat;
    background-size: 26vw;
    background-position: 20vw 26vw;
    display: flex;
    justify-content: flex-start;
}

.csTitle {
    position: absolute;
    top: 68vw; /* Adjust based on your layout */
    left: 21vw; /* Adjust based on your layout */
    height: 20vw;
    width: 18vw;
}

.csDescription h1 {
    position: absolute;
    top: 80vw; /* Adjust based on your layout */
    left: 32vw; /* Adjust based on your layout */
    font-size: 2.5vw;
    width: 40vw;
    letter-spacing: .1vw;
    font-family: 'Delicious Handrawn', cursive;
    font-weight: lighter;
    color: rgb(108, 108, 108);
    text-align: center;
}

.artDescription {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.artDescriptionText {
    position: absolute;
    top: 147vw; /* Adjust based on your layout */
    left: 49vw; /* Adjust based on your layout */
    font-size: 2.5vw;
    width: 40vw;
    letter-spacing: .1vw;
    font-family: 'Delicious Handrawn', cursive;
    font-weight: lighter;
    color: rgb(108, 108, 108);
    text-align: center;
}

.artTitle {
    position: absolute;
    top: 135vw; /* Adjust based on your layout */
    left: 77vw; /* Adjust based on your layout */
    height: 13vw;
    width: 9vw;
}

.woodTitle {
    position: absolute;
    top: 207vw; /* Adjust based on your layout */
    left: 7vw; /* Adjust based on your layout */
    height: 19vw;
    width: 13vw;
}

.interestDescription {
    background-image: url("/Users/ethantam/Desktop/etweb/src/assets/Interest.png");
    background-repeat: no-repeat;
    background-size: 20vw;
    background-position: 6vw 13vw;
    display: flex;
    justify-content: flex-start;
}

.interestDescription h1 {
    position: absolute;
    top: 220vw; /* Adjust based on your layout */
    left: 20vw; /* Adjust based on your layout */
    font-size: 2.5vw;
    width: 40vw;
    letter-spacing: .1vw;
    font-family: 'Delicious Handrawn', cursive;
    font-weight: lighter;
    color: rgb(108, 108, 108);
    text-align: center;
}

.contactDescription {
    display: flex;
    position: absolute;
    top: 275vw; /* Adjust based on your layout */
}

.contact {
    padding-top: 15vw;
    padding-left: 22vw;
    height: 15vw;
    width: 20vw;
}

.contact-links {
    padding-top: 10vw;
    padding-left: 6vw;
    position: absolute;
    top: 13vw; /* Adjust based on your layout */
}

/* .contact-links f {
    padding-top: 1.2%;
    padding-right: 1vw;
} */

.contactLogo {
    width: 7vw;
    height: 7vw;
}

.lastLine {
    position: absolute;
    top: 311vw; /* Adjust based on your layout */
    left: 38vw; /* Adjust based on your layout */
    font-family: 'Delicious Handrawn', cursive;
    letter-spacing: .1vw;
    font-weight: lighter;
    color: rgb(108, 108, 108);
    text-align: center;
    font-size: 2vw;
    padding-bottom: 3vw;
    padding-top: 4vw;
    /* background-image: url("/Users/ethantam/Desktop/etweb/src/assets/StaminaAndHeart.png");
    background-size: 5vw;
    background-repeat: no-repeat;
    background-position: 146.7vw 150vw; */
}

.heartAndStamina{
    position: absolute;
    bottom: 2vw;
    left: 9.5vw;
    width: 5vw;
    height: 5vw;
}
