.art {
    width: 100vw;
    height: 410vw;
    background-repeat: no-repeat;
    background-size: 100vw;
    background-image: url("/Users/ethantam/Desktop/etweb/src/assets/Art_Background_Expanded.jpg");
}

.artHeaderDescription {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.artHeader {
    padding-top: 22vw;
    padding-left: 41vw;
    padding-bottom: 26vw;
    height: 11vw;
    width: 16vw; 
}
