* {
    z-index: 9999999;
}

.Nav {
    width: 100vw;

    top: 0;
    position: fixed;

    display: flex;
    flex-direction: row;
    justify-content: flex-between;
    align-items: center;

    z-index: 99999999;
}

ul {
    width: 100vw;

    display: flex;
    justify-content: center;

    list-style-type: none;

    margin: 0;
    padding-top: .25vw;
    padding-right: 4vw;
}

li a {
    display: block;
    text-align: center;

    color: #a9a9a9;
    text-decoration: none;
    font-family: 'Delicious Handrawn', cursive;
    font-size: 2vw;

    backdrop-filter: blur(5px);
    border-radius: 50px;
    border: solid;
    border-color: transparent;

    margin-left: .5vw;
    margin-right: .5vw;
    padding-left: .5vw;
    padding-top: .5vw;
    padding-right: .5vw;

    z-index: 9999999;
}

ex {
    padding-top: 2vw;
}

.logo {
    height: 6vw;
    width: 6vw; 
}

.scroll-down {
    height: 50vw;
    width: 30vw;
    border: 2px solid black;
    position: absolute;
    left: 50%;
    bottom: 20px;
    border-radius: 50px;
    cursor: pointer;
  }
  .scroll-down::before,
  .scroll-down::after {
    content: "";
    position: absolute;
    top: 20%;
    left: 50%;
    height: 10px;
    width: 10px;
    transform: translate(-50%, -100%) rotate(45deg);
    border: 2px solid black;
    border-top: transparent;
    border-left: transparent;
    animation: scroll-down 1s ease-in-out infinite;
  }
  .scroll-down::before {
    top: 30%;
    animation-delay: 0.3s;
    /* animation: scroll-down 1s ease-in-out infinite; */
  }
  