.csExperience {
    border: 50vw;
    padding-top: 6vw;
    display: flex;
    justify-content: flex-start;
}

.cs-Project-Description {
    padding-left: 10vw;
    display: flex;
    justify-content: flex-start;
}

.cs-Project-Small-Description {
    font-family: Arial, Helvetica, sans-serif;
}

.cs-Project-Name {
    width: 40vw;
    font-size: 3vw;
    font-family: 'Delicious Handrawn', cursive;
    letter-spacing: .1vw;
    color: rgb(238, 233, 255);
}

.cs-Project-Title {
    width: 20vw;
    padding-top: .8vw;
    font-size: 2vw;
    font-family: 'Delicious Handrawn', cursive;
    font-weight: lighter;
    color: white;
}

.cs-Project-Time {
    padding-top: 1vw;
    width: 20vw;
    font-size: 2vw;
    font-family: 'Delicious Handrawn', cursive;
    font-style: italic;
    color: white;
}

.cs-Project-Text {
    padding-top: 1vw;
    font-size: 1.7vw;
    width: 40vw;
    font-family: 'Delicious Handrawn', cursive;
    color: white;
}

.cs-Project-Link {
    padding-top: 1vw;
    font-size: 1.7vw;
    width: 40vw;
    font-family: 'Delicious Handrawn', cursive;
    color: white;
}

.csPic {
    padding-left: 10vw;
    padding-top: 1vw;
    width: 30vw;
    height: 23vw;
    justify-content: center;
}